import React from 'react';

import { ErrorContent, ErrorContentProps } from './ErrorContent';
import { ComponentProps, PageContainer, usePublicPath } from '@Components';

type Brand = 'holidaypirates' | 'standard';
export interface ErrorPageProps extends ErrorContentProps, ComponentProps {
  brand?: Brand;
  actions?: React.ReactNode;
}

/**
 * Component for displaying full page errors.
 * Applications should add tracking when shown.
 */

/**
 * IMPORTANT: if you change images, you should update name, so it will be a new cache image.
 */
const urlImage: Record<Brand, string> = {
  standard: 'standard_error_image_04.svg',
  holidaypirates: 'holidaypirates_error_image_04.svg',
};

export const ErrorPage: React.FC<React.PropsWithChildren<ErrorPageProps>> = ({
  brand = 'standard',
  title,
  subtitle,
  content,
  children,
  actions,
  className,
}) => {
  const publicPath = usePublicPath();

  return (
    <PageContainer>
      <div
        className={className}
        sx={{
          boxSizing: 'border-box',
          display: 'grid',
          gridTemplateColumns: [ '1fr', '1fr 1fr' ],
          gridTemplateRows: [ 'auto auto', '1fr' ],
          marginX: 'auto',
          marginY: [ 'xl', '4xl' ],
          gap: [ 'xs', '2xl' ],
          alignItems: [ null, 'center' ],
        }}
      >
        <div
          sx={{
            maxWidth: '100%',
            width: '100%',
            marginX: 'auto',
            alignSelf: 'center',
          }}
        >
          <div
            sx={{
              backgroundImage: `url(${publicPath}${urlImage[brand]})`,
              backgroundSize: 'cover',
              width: '100%',
              paddingBottom: '65%',
            }}
          />
        </div>
        <div>
          <ErrorContent
            title={title}
            subtitle={subtitle}
            content={content}
          >
            {children}
          </ErrorContent>
          {actions && (
            <div
              sx={{
                marginTop: '2xl',
                display: 'flex',
                flexDirection: [ 'column', null, 'row' ],
                gap: '2xs',
                '& > *': {
                  width: [ '100%', null, 'auto' ],
                },
              }}
            >
              {actions}
            </div>
          )}
        </div>
      </div>
    </PageContainer>
  );
};

