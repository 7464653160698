import React, { Fragment } from 'react';

import { Paragraph, Title, Label } from '@Components';

export interface ErrorContentProps {
  title: React.ReactNode;
  subtitle?: React.ReactNode;
  content?: React.ReactNode;
}

/**
 * Component for displaying errors.
 * Intended to either be part of a modal, or the `ErrorPage` component.
 *
 * Applications should add tracking when shown.
 */
export const ErrorContent: React.FC<React.PropsWithChildren<ErrorContentProps>> = (
  {
    title,
    subtitle,
    content,
    children,
  },
) => (
  <Fragment>
    <div
      sx={{
        marginBottom: 'xl',
      }}
    >
      <Title
        as="h1"
        variant="large"
      >
        {title}
      </Title>
      {subtitle && (
        <Label
          as="span"
          variant="extralarge"
          sx={{
            display: 'block',
            marginTop: 'xs',
            fontWeight: 'normal',
          }}
        >
          {subtitle}
        </Label>
      )}
    </div>
    {content && (
      <Paragraph
        variant="large"
        sx={{ marginBottom: children ? '2xl' : 0 }}
      >
        {content}
      </Paragraph>
    )}
    {children}
  </Fragment>
);

